import React from "react";
import styles from "./Bio.module.css";
import photo from "./static/marathon_canne_200_200.jpg";

function Bio(props) {
  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ""}
    `}
    >
      <img src={photo} alt="Logo marathon canne à sucre 2019" />
      <p>
        Tour des producteurs de rhum agricole martiniquais, par Grom, Azou, Piet
        et Hebi.
      </p>
    </div>
  );
}

export default Bio;
