export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Marathon Canne à Sucre 2019",

  author: "Damien Lebreuilly",
  description: "Un tour des distilleries martiniquaise",

  // The number of posts to a page on the site index.
  indexPageSize: 10
};
