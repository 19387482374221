export let months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

export function formatDate(dateString) {
  let date = new Date(dateString);
  let monthName = months[date.getMonth()];
  let dayOfMonth = date.getDate();
  let fourDigitYear = date.getFullYear();

  return `${dayOfMonth} ${monthName} ${fourDigitYear}`;
}
